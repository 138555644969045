module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"position":"fixed","display":"flex","flexDirection":"column","justifyContent":"center","backgroundColor":"rgba(0, 0, 0, 0.75)","zIndex":999,"overflow":"auto","top":0,"left":0,"bottom":0,"right":0,"padding":0},"content":{"top":0,"left":0,"bottom":0,"right":0,"padding":0,"position":"relative","border":"none","background":"none","borderRadius":0,"overflow":"auto","WebkitOverflowScrolling":"touch","width":"calc(100% - 1.5rem)","maxWidth":"1170px","margin":"0 auto"}},"contentLabel":"Modal"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
